



































import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "PrevNextButtons",
  props: {
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    haveNext: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    haveSubmit: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    }
  },
  setup(props, { emit }) {
    function prev() {
      emit("prev");
    }

    function next() {
      emit("next");
    }

    function submit() {
      emit("submit");
    }

    return {
      prev,
      next,
      submit
    };
  },
});
