import CreateCustomerParams from "@/api/interfaces/CreateCustomerParams";
import { getAnonymousId } from "@/services/analytics";
import publicIp from "public-ip";
import learnlinkAPI from "./learnlinkAPI";
import { ROUTES } from "./routes";

export interface CreateSellerAccount {
  address: string,
  birthdate: string,
  city: string,
  email: string;
  firstName: string,
  lastName: string,
  password: string,
  phone: string,
  postalCode: string,
}

export const createSellerAccount = async(seller: CreateSellerAccount): Promise<CreateSellerAccount> => {
  const usersRes = await learnlinkAPI.axios.post<CreateSellerAccount>(
    ROUTES.CREATE_SELLER,
    {
      ...seller,
      registrationIP: await publicIp.v4(),
      anonymousID: getAnonymousId(),
    });
  return usersRes.data;
};

export interface CreateCustomerAccount {
  customerType?: string;
  email: string;
  firstName: string,
  lastName: string,
  password: string,
  phone: string,
  studentName?: string,
}

export const createCustomerAccount = async(customer: CreateCustomerAccount): Promise<string> => {
  const usersRes = await learnlinkAPI.axios.post(
    ROUTES.CREATE_CUSTOMER,
    {
      ...customer,
      registrationIP: publicIp.v4(),
      anonymousID: getAnonymousId(),
    });
  return usersRes.data.uid;
};

export const createContactRequest = async(customer: CreateCustomerParams): Promise<string> => {
  const accountsRes = await learnlinkAPI.axios.post(
    ROUTES.CONTACT_ME,
    customer,
  );
  return accountsRes.data;
};

export const createOrderRequest = async(customer: CreateCustomerParams): Promise<string> => {
  const accountsRes = await learnlinkAPI.axios.post(
    ROUTES.ORDER_SELLER,
    customer,
  );
  return accountsRes.data;
};
