


































import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";
import PickerItems from "@/interfaces/PickerItems";

export default defineComponent({
  name: "ButtonPicker",
  props: {
    items: {
      type: Array as PropType<PickerItems[]>,
      required: true
    },
    value: {
      type: String as PropType<string>,
      required: true
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      if (_value.value) emit("input", _value.value);
    });

    const select = (selected: string) => {
      _value.value = selected;
      emit("selected", selected);
    };

    return {
      select,
    };
  },
});
