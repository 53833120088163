export enum ROUTES {
  ACCOUNT = "/accounts/*",
  CONTACT_ME = "/app/customers/contact-me",
  CREATE_CUSTOMER = "/createCustomer/webflow",
  CREATE_ONE_LESSON = "/app/lessons/0",
  CREATE_TEN_LESSONS = "/app/lessons/10",
  CREATE_SELLER = "/createSeller",
  ORDER_SELLER = "/app/customers/order-seller",
  PROFILE = "/profiles/*",
  RATE_LESSON = "/lessons/rate/*",
  SEND_FIRST_REPORT = "/reports/first",
  SEND_PERIODICAL_REPORT = "/reports/periodical",
  SEND_REGULAR_REPORT = "/reports/",
  USER = "/users/*",
}
