








































import { defineComponent, reactive } from "@vue/composition-api";
import LoginForm from "@/components/app/login/LoginForm.vue";
import ForgotPasswordForm from "@/components/app/login/ForgotPasswordForm.vue";

export default defineComponent({
  components: { LoginForm, ForgotPasswordForm },
  name: "Login",
  setup() {
    const vm = reactive({
      step: 1
    });

    return {
      vm
    };
  }
});
