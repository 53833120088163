import * as Stripe from "@stripe/stripe-js";
import environment from "@/config/environment";

class StripeService {
  private _instance: Stripe.Stripe | null = null;

  async setup() {
    if (!environment.stripeKey) {
      throw new Error("stripeKey missing.");
    }
    else {
      this._instance = await Stripe.loadStripe(environment.stripeKey);
    }
  }

  get api() {
    if (!this._instance) {
      throw new Error("Stripe API accessed before it was initialized!");
    }
    return this._instance;
  }
}

export default new StripeService();
