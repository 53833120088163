import { RoutesConfig } from "@/router/Routes";
import { uiModule } from "@/store/modules/ui";
import { userModule } from "@/store/modules/user";

type MenuItem = {
  title: string;
  route: string;
  icon: string;
  actionText?: string;
  counter?: number;
  divider?: "top" | "bottom";
};
type MenuItems = MenuItem[];

export const getMenuItems = (showSellerRoutes: boolean): MenuItems => {
  const commonRoutes: MenuItems = [
    {
      title: "Innstillinger",
      route: showSellerRoutes
        ? RoutesConfig.SELLER_SETTINGS.path
        : RoutesConfig.CUSTOMER_SETTINGS.path,
      icon: "mdi-cog",
    },
  ];

  const sellerRoutes: MenuItems = [
    {
      title: RoutesConfig.SELLER_DASHBOARD.name,
      route: RoutesConfig.SELLER_DASHBOARD.path,
      icon: "mdi-view-dashboard",
    },
    {
      title: RoutesConfig.SELLER_NEWS.name,
      route: RoutesConfig.SELLER_NEWS.path,
      icon: "mdi-newspaper-variant",
      counter: uiModule.state.unreadNewsMessageCount,
    },
    {
      title: RoutesConfig.SELLER_LESSONS.name,
      route: RoutesConfig.SELLER_LESSONS.path,
      icon: "mdi-calendar-month",
    },
    {
      title: RoutesConfig.SELLER_REPORTS.name,
      route: RoutesConfig.SELLER_REPORTS.path,
      icon: "mdi-trending-up",
    },
    {
      title: RoutesConfig.SELLER_PAYOUTS.name,
      route: RoutesConfig.SELLER_PAYOUTS.path,
      icon: "mdi-currency-usd",
    },
    {
      title: RoutesConfig.SELLER_AVAILABILITY.name,
      route: RoutesConfig.SELLER_AVAILABILITY.path,
      icon: "mdi-account-arrow-right",
    },
    {
      title: RoutesConfig.SELLER_PROFILE.name,
      route: RoutesConfig.SELLER_PROFILE.getPath(userModule.state.userId),
      icon: "mdi-account",
      actionText: `${userModule.getters.getFullName()}`,
    },
    {
      title: "",
      route: "",
      icon: "",
      divider: "bottom",
    },
    {
      title: RoutesConfig.SELLER_RESOURCES.name,
      route: RoutesConfig.SELLER_RESOURCES.path,
      icon: "mdi-space-invaders",
    },
    {
      title: RoutesConfig.VIDEOS.name,
      route: RoutesConfig.VIDEOS.path,
      icon: "mdi-movie-play",
    },
    {
      title: "",
      route: "",
      icon: "",
      divider: "bottom",
    },
  ];

  const customerRoutes: MenuItems = [
    {
      title: "Oversikt",
      route: RoutesConfig.CUSTOMER_DASHBOARD.path,
      icon: "mdi-view-dashboard",
    },
    {
      title: RoutesConfig.CUSTOMER_LESSONS.name,
      route: RoutesConfig.CUSTOMER_LESSONS.path,
      icon: "mdi-calendar-month",
    },
    {
      title: RoutesConfig.CUSTOMER_REPORTS.name,
      route: RoutesConfig.CUSTOMER_REPORTS.path,
      icon: "mdi-trending-up",
    },
    {
      title: "Medlemskap",
      route: RoutesConfig.CUSTOMER_MEMBERSHIP.path,
      icon: "mdi-crown",
    },
    {
      title: RoutesConfig.CUSTOMER_NEWS.name,
      route: RoutesConfig.CUSTOMER_NEWS.path,
      icon: "mdi-newspaper-variant",
      counter: uiModule.state.unreadNewsMessageCount,
    },
  ];

  return showSellerRoutes ? sellerRoutes.concat(commonRoutes) : customerRoutes.concat(commonRoutes);
};
