













































































































































































































































export default {
  name: "TermsOfUseSeller",
};
