

























































































































import { auth } from "@/services/firebase";
import { createContactRequest } from "@/api/customerService";
import { CustomerType } from "@/interfaces/User";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { RoutesConfig } from "@/router/Routes";
import { SalesTimesWeekdaysPickerItems, Weekday } from "@/helpers/availabilityUtils";
import { TargetGroup } from "@/interfaces/Project";
import { useNotifier } from "@/providers/notifier";
import { useRouter } from "@/router";
import ButtonPicker from "@/components/onboarding/ButtonPicker.vue";
import ContactInformation from "@/interfaces/ContactInformation";
import ContactInformationForm from "@/components/onboarding/ContactInformationForm.vue";
import environment from "@/config/environment";
import handleError from "@/helpers/errors";
import PickerItems from "@/interfaces/PickerItems";
import PrevNextButtons from "@/components/onboarding/PrevNextButtons.vue";
import TermsOfUse from "@/components/terms-of-use/TermsOfUse.vue";
import WeekdayPicker from "@/components/WeekdayPicker.vue";

import analytics, { getAnonymousId } from "@/services/analytics";

export default defineComponent({
  name: "ContactMe",
  components: {
    PrevNextButtons,
    ButtonPicker,
    ContactInformationForm,
    WeekdayPicker,
    TermsOfUse,
  },
  setup() {
    const notify = useNotifier();
    const { navigate, currentRoute } = useRouter();
    const vm = reactive({
      consent: false,
      contactInformation: {
        customerType: "" as CustomerType,
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        phone: "",
        studentName: "",
      } as ContactInformation,
      loading: false,
      step: 1,
      targetGroup: "" as TargetGroup,
      valid: false,
      weekdays: [] as Weekday[],
    });

    let registrationURL: string;

    onMounted(() => {
      analytics.track(`contactMeStep${vm.step}`);
      registrationURL = currentRoute.value.fullPath;

      const targetGroup = currentRoute.value.params.targetGroup;
      if (targetGroup) {
        if (targetGroup === "barneskole") {
          vm.targetGroup = "elementary";
          next();
        }
        else if (targetGroup === "ungdomskole") {
          vm.targetGroup = "middle";
          next();
        }
        else if (targetGroup === "vgs") {
          vm.targetGroup = "high";
          next();
        }
        else if (targetGroup === "kodekurs") {
          vm.targetGroup = "code";
          next();
        }
      }
    });

    function next(): void {
      vm.step++;

      // WARNING: Possible issues when sending a nested object.
      analytics.track(`contactMeStep${vm.step}`, vm);
    }

    let anonymousID: string;
    try {
      anonymousID = getAnonymousId();
    }
    catch (e) {
      handleError(e);
    }

    async function submit() {
      vm.loading = true;

      try {
        await createContactRequest({
          ...vm.contactInformation,
          anonymousID,
          channel: "contactMe",
          registrationURL,
          targetGroup: vm.targetGroup,
          weekdays: vm.weekdays,
        });
        next();

        await auth.signInWithEmailAndPassword(vm.contactInformation.email, vm.contactInformation.password);
        navigate(RoutesConfig.CUSTOMER_DASHBOARD.path);
      }
      catch (e) {
        handleError(e);
        if (e.response.status === 409) {
          notify({
            title: "E-posten er allerede i bruk! 🤯",
            message: "Du kan logge inn på app.learnlink.no.",
            type: "error",
          });
        }
        else {
          notify({
            title: "Registrering feilet! 🤯",
            message: "Noe gikk galt under registreringen av informasjonen. Vennligst kontakt support dersom problemet vedvarer.",
            type: "error",
          });
        }
      }
      vm.loading = false;
    }

    const targetGroups: PickerItems[] = [
      {
        ID: "elementary",
        icon: "mdi-bus-school",
        name: "Barneskole",
      },
      {
        ID: "middle",
        icon: "mdi-book-open-variant",
        name: "Ungdomskole",
      },
      {
        ID: "high",
        icon: "mdi-school",
        name: "Videregående",
      },
      {
        ID: "code",
        icon: "mdi-laptop",
        name: "Kodekurs (6-13 år)",
      },
    ];

    function toLogin(): void {
      if (environment.firebase.projectId === "learnlink-prod") {
        window.location.href = "https://app.learnlink.no";
      }
      else if (environment.firebase.projectId === "learnlink-stag") {
        window.location.href = "https://app.staging.learnlink.no";
      }
    }

    function toReadMore(): void {
      window.location.href = "https://www.learnlink.no/var-metode";
    }

    return {
      next,
      SalesTimesWeekdaysPickerItems,
      submit,
      targetGroups,
      toLogin,
      toReadMore,
      vm,
    };
  },
});
