import { Store } from "vuex";
import { Module, registerModule } from "vuex-smart-module";
import { BA, BG, BM, Committer, Dispatcher } from "vuex-smart-module/lib/assets";
import { Class } from "vuex-smart-module/lib/utils";

export const dynamicModule = <S, G extends BG<S>, M extends BM<S>, A extends BA<S, G, M>, T = unknown>(name: string, options: {
    getters: Class<G>,
    mutations: Class<M>,
    actions: Class<A>
  }): {
      register: (store: Store<T>, initialState: Class<S>) => void,
      module: Module<S, G, M, A>,
      state: S,
      getters: G
      mutations: Committer<M>,
      actions: Dispatcher<A>,
  } => {
  let registeredModule: Module<S, G, M, A> | null = null;
  let registeredStore: Store<T> | null = null;
  return {
    register: (store, initialState) => {
      registeredModule = new Module({
        state: initialState,
        getters: options.getters,
        mutations: options.mutations,
        actions: options.actions,
      });

      registerModule(store, name, `${name}/`, registeredModule);

      registeredStore = store;
    },
    get module() {
      if (registeredModule == null) {
        throw new Error(`Dynamic ${name} module is accessed, but not registered`);
      }
      return registeredModule;
    },
    get state() {
      if (registeredModule == null || registeredStore == null) {
        throw new Error(`Dynamic ${name} module is accessed, but not registered`);
      }
      return registeredModule.context(registeredStore).state;
    },
    get actions() {
      if (registeredModule == null || registeredStore == null) {
        throw new Error(`Dynamic ${name} module is accessed, but not registered`);
      }
      return registeredModule.context(registeredStore).actions;
    },
    get mutations() {
      if (registeredModule == null || registeredStore == null) {
        throw new Error(`Dynamic ${name} module is accessed, but not registered`);
      }
      return registeredModule.context(registeredStore).mutations;
    },
    get getters() {
      if (registeredModule == null || registeredStore == null) {
        throw new Error(`Dynamic ${name} module is accessed, but not registered`);
      }
      return registeredModule.context(registeredStore).getters;
    },
  };
};
