import { NewsMessage } from "@/interfaces/NewsMessage";
import learnlinkAPI from "./learnlinkAPI";
import { userModule } from "@/store/modules/user";

export const getAllNewsMessagesForUser = async(isSeller: boolean): Promise<NewsMessage[]> => {
  const res = await learnlinkAPI.axios.get<NewsMessage[]>(`/notifications/getAllNewsMessagesForUser/${isSeller}`);
  return res.data;
};

export const getUnreadNewsMessagesCount = async(): Promise<number> => {
  const res = await learnlinkAPI.axios.get<number>(`/notifications/getUnreadNewsMessageCount/${userModule.state.userId}`);
  return res.data;
};
