





































import handleError, { getAuthErrorNotification } from "@/helpers/errors";
import { useNotifier } from "@/providers/notifier";
import { auth } from "@/services/firebase";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "ForgotPasswordForm",
  setup(props, { emit }) {
    const notify = useNotifier();

    const vm = reactive({
      email: "",
      loading: false
    });

    async function cancel() {
      emit("cancel");
    }

    async function resetPassword() {
      vm.loading = true;

      try {
        await auth.sendPasswordResetEmail(vm.email);
        emit("success");
        notify({
          title: "Sjekk e-posten din",
          message: "Vi har sendt deg en link du må åpne for å endre passordet.",
          type: "success"
        });
      }
      catch (e) {
        handleError(e);
        notify(getAuthErrorNotification(e.code));
      }

      vm.loading = false;
    }

    return {
      cancel,
      resetPassword,
      vm
    };
  },
});
