

























































import ContactInformation from "@/interfaces/ContactInformation";
import { defineComponent, onMounted, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import rules, { Rule } from "@/helpers/formRules";

export default defineComponent({
  name: "ContactInformationFormStepper",
  props: {
    value: {
      type: Object as PropType<ContactInformation>,
      required: true
    }
  },
  setup(props, { emit }) {
    const _value = reactive(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    interface Field {
      step: number;
      name: string;
      prop: string;
      rules: Rule[];
      type: string;
    }

    const fields: Field[] = [
      { step: 1, name: "Forelders fornavn", prop: "firstName", rules: [], type: "text" },
      { step: 2, name: "Forelders etternavn", prop: "lastName", rules: [], type: "text" },
      { step: 3, name: "Elevens fornavn", prop: "studentName", rules: [], type: "text" },
      { step: 4, name: "Din e-postadresse", prop: "email", rules: [rules.email], type: "text" },
      { step: 5, name: "Ditt telefonnummer", prop: "phone", rules: [rules.phone], type: "text" },
      { step: 6, name: "Velg et passord", prop: "password", rules: [rules.min], type: "password" },
    ];

    const textfield = ref(null);

    const vm = reactive({
      repeatPassword: "",
      showPassword: false,
      showRepeatPassword: false,
      step: 1
    });

    function focusField(): void {
      const ignoreFirstElemIndex = vm.step - 1;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const fieldRef = textfield.value[ignoreFirstElemIndex];
      fieldRef.focus();
    }

    function next(): void {
      // TODO: Form validation.
      vm.step++;
      if (vm.step === 7) emit("success");
      else setTimeout(() => focusField(), 400);
    }

    onMounted(() => {
      setTimeout(() => focusField(), 600);
    });

    // TODO: Form validation should set _value.valid = true;

    return {
      _value,
      fields,
      next,
      rules,
      textfield,
      vm
    };
  }
});
