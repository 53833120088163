export type Rule = (value: string) => string | true;

const rules = {
  email: (value: string): true | string => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (pattern.test(value) || "Ugyldig epostadresse.");
  },
  match: (value1: string, value2: string): true | string => value1 === value2 || "Passordene må være like.",
  min: (v: string): true | string => v.length >= 8 || "Minst 8 tegn.",
  personNumber: (v: string): true | string => /^[0-9]*$/.test(v) || "Ugyldig personnummer",
  phone: (v: string): true | string => /^[1-9][0-9]{7}$/.test(v) || "Ugyldig telefonnummer",
  postalCode: (v: string): true | string => /^[0-9]*$/.test(v) || "Ugyldig postnummer",
  required: (v: string): true | string => !!v || "Feltet er obligatorisk",
};

export default rules;
