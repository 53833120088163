import Profile from "@/interfaces/Profile";

export type Weekday =
  "monday" |
  "tuesday" |
  "wednesday" |
  "thursday" |
  "friday" |
  "saturday" |
  "sunday";

export enum Weekdays {
  monday = "Mandager",
  tuesday = "Tirsdager",
  wednesday = "Onsdager",
  thursday = "Torsdager",
  friday = "Fredager",
  saturday = "Lørdager",
  sunday = "Søndager",
}

export const weekdays = [
  {
    value: "monday",
    text: "Mandager",
  },
  {
    value: "tuesday",
    text: "Tirsdager",
  },
  {
    value: "wednesday",
    text: "Onsdager",
  },
  {
    value: "thursday",
    text: "Torsdager",
  },
  {
    value: "friday",
    text: "Fredager",
  },
  {
    value: "saturday",
    text: "Lørdager",
  },
  {
    value: "sunday",
    text: "Søndager",
  },
];

export const LessonTimes = "14 og 21";

export const LessonTimesWeekdaysPickerItems = [
  {
    value: "monday",
    text: `${Weekdays.monday} mellom ${LessonTimes}`,
  },
  {
    value: "tuesday",
    text: `${Weekdays.tuesday} mellom ${LessonTimes}`,
  },
  {
    value: "wednesday",
    text: `${Weekdays.wednesday} mellom ${LessonTimes}`,
  },
  {
    value: "thursday",
    text: `${Weekdays.thursday} mellom ${LessonTimes}`,
  },
  {
    value: "friday",
    text: `${Weekdays.friday} mellom ${LessonTimes}`,
  },
];

export const SalesTimes = "08 og 19";

export const SalesTimesWeekdaysPickerItems = [
  {
    value: "monday",
    text: `${Weekdays.monday} mellom ${SalesTimes}`,
  },
  {
    value: "tuesday",
    text: `${Weekdays.tuesday} mellom ${SalesTimes}`,
  },
  {
    value: "wednesday",
    text: `${Weekdays.wednesday} mellom ${SalesTimes}`,
  },
  {
    value: "thursday",
    text: `${Weekdays.thursday} mellom ${SalesTimes}`,
  },
  {
    value: "friday",
    text: `${Weekdays.friday} mellom ${SalesTimes}`,
  },
];

export function isAvailable(sellerProfile: Profile): boolean {
  const availability = sellerProfile.availability;
  if (!availability) return false;

  return (availability.monday.length ||
    availability.tuesday.length ||
    availability.wednesday.length ||
    availability.thursday.length ||
    availability.friday.length ||
    availability.saturday.length ||
    availability.sunday.length) > 0;
}

export function mapWeekday(weekday: string): string {
  return Weekdays[weekday as Weekday];
}
