import "./setup";
import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import "@/hooks/swUpdate";
import "./registerServiceWorker";
import { gucciRouter } from "./router";
import store from "./store";
import handleError from "./helpers/errors";

Vue.config.productionTip = false;

Vue.config.errorHandler = (err: Error) => {
  handleError(err);
  // TODO: APP-3028: Dispatch notification to UI store.
};

const VueApp = new Vue({
  router: gucciRouter,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

declare global {
  interface Window {
    Cypress: unknown;
    app: {
        Vue: Vue;
        store: typeof store;
    }
  }
}

if (window.Cypress) {
  window.app = {
    Vue: VueApp,
    store,
  };
}
