



















































































import { getUser } from "@/api/userService";
import SellerRegistration from "@/components/SellerRegistration.vue";
import { useRouter } from "@/router";
import { RoutesConfig } from "@/router/Routes";
import { useModule } from "@/store/hooks";
import authModule from "@/store/modules/auth";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "Register",
  components: {
    SellerRegistration,
  },
  setup() {
    const isLoading = ref(true);
    const { state } = useModule(authModule);
    const { navigate } = useRouter();

    onMounted(async() => {
      if (state.isAuthorized && state.uid) {
        const user = await getUser(state.uid);
        if (user.seller) window.location.href = RoutesConfig.SELLER_MY_STUDENTS.path;
        else window.location.href = RoutesConfig.CUSTOMER_DASHBOARD.path;
        // navigate(RoutesConfig.LOGIN.path);
      }
      isLoading.value = false;
    });

    function navigateToLogin() {
      navigate(RoutesConfig.LOGIN.path);
    }

    return {
      isLoading,
      navigateToLogin,
    };
  },
});
