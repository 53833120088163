import { InjectionKey, provide, inject } from "@vue/composition-api";

export type Notification = {
  title: string;
  message: string;
  type: "success" | "error" | "warning" | "info";
};

export type Notifier = (notification: Notification) => void;

const NotiferSymbol: InjectionKey<Notifier> = Symbol("notifier");

export function provideNotifier(notifier: Notifier): void {
  provide(NotiferSymbol, notifier);
}

export function useNotifier(): Notifier {
  const notifier = inject(NotiferSymbol);
  if (!notifier) {
    throw new Error("Notifier not provided");
  }
  return notifier;
}
