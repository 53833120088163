import { Store } from "vuex";
import { Context, Module } from "vuex-smart-module";
import { BA, BG, BM } from "vuex-smart-module/lib/assets";
import store from "./index";

/**
 * Returns VuexSmartModule context for the specified module
 */
export const useModule = <S, G extends BG<S>, M extends BM<S>, A extends BA<S, G, M>>(
  module: Module<S, G, M, A>,
  injectedStore: Store<S> = store,
): Context<Module<S, G, M, A>> => {
  return module.context(injectedStore);
};
