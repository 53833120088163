import { Module, createStore } from "vuex-smart-module";

import Vue from "vue";
import Vuex from "vuex";
import authModule from "./modules/auth";

Vue.use(Vuex);

export default createStore(
  new Module({
    modules: {
      authModule,
    },
  }),
  {
    strict: process.env.NODE_ENV !== "production",
  },
);
