import { getUnreadNewsMessagesCount } from "@/api/newsService";
import { Getters, Mutations, Actions } from "vuex-smart-module";
import { dynamicModule } from "../dynamicModuleUtil";

interface UIStateInterface {
  unreadNewsMessageCount: number
}

class UIGetters extends Getters<UIStateInterface> {
}

class UIMutations extends Mutations<UIStateInterface> {
  setUnreadNewsMessageCount(payload: number) {
    this.state.unreadNewsMessageCount = payload;
  }
}

class UIActions extends Actions<
  UIStateInterface,
  UIGetters,
  UIMutations,
  UIActions
  > {
  async fetchUnreadNewsMessageCount() {
    let unreadNewsMessageCount: number;
    try {
      unreadNewsMessageCount = await getUnreadNewsMessagesCount();
      this.mutations.setUnreadNewsMessageCount(unreadNewsMessageCount);
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
}

export const uiModule = dynamicModule<UIStateInterface, UIGetters, UIMutations, UIActions>(
  "dynamicUI", {
    getters: UIGetters,
    mutations: UIMutations,
    actions: UIActions,
  });
