import environment from "@/config/environment";
import { auth } from "@/services/firebase";
import { captureException, configureScope } from "@sentry/vue";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { requestId } from "./apiUtils";

const onRequestHandler = async(
  requestConfig: AxiosRequestConfig,
): Promise<AxiosRequestConfig> => {
  configureScope(scope => scope.setTags({
    "request_id": requestId(),
    "request_url": requestConfig.url,
  }));
  if (auth.currentUser) {
    const token = await auth.currentUser?.getIdToken();
    requestConfig.headers.Authorization = "Bearer " + token;
  }

  return requestConfig;
};

const sentryInterceptor = (error: Error) => {
  // Log all exceptions to sentry
  captureException(error);
  return Promise.reject(error);
};

const axiosInstance = axios.create({
  baseURL: environment.apiURL,
  timeout: 60 * 1000,
  withCredentials: false,
});

axiosInstance.interceptors.request.use(onRequestHandler, sentryInterceptor);
axiosInstance.interceptors.response.use(undefined, sentryInterceptor);

export default {
  axios: axiosInstance,
  setup: function(config: AxiosRequestConfig): AxiosInstance {
    this.axios = axios.create(config);
    this.axios.interceptors.request.use(undefined, sentryInterceptor);
    this.axios.interceptors.response.use(undefined, sentryInterceptor);
    return this.axios;
  },
};
