import Account from "@/interfaces/Account";
import Profile from "@/interfaces/Profile";
import User from "@/interfaces/User";
import learnlinkAPI from "./learnlinkAPI";

type PartialWithUserId<T> = Partial<T> & {
  uid: string;
};

export const updateAccount = async(account: PartialWithUserId<Account>): Promise<Account> => {
  const accountsRes = await learnlinkAPI.axios.put<Account>(
    `/accounts/${account.uid}`,
    account,
  );
  return accountsRes.data;
};

export const updateProfile = async(profile: PartialWithUserId<Profile>): Promise<Profile> => {
  const profilesRes = await learnlinkAPI.axios.put<Profile>(
    `/profiles/${profile.uid}`,
    profile,
  );
  return profilesRes.data;
};

export const updateUser = async(user: PartialWithUserId<User>): Promise<User> => {
  const usersRes = await learnlinkAPI.axios.put<User>(
    `/users/${user.uid}`,
    user,
  );
  return usersRes.data;
};

export const getAccount = async(userId: string): Promise<Account> => {
  const accountsRes = await learnlinkAPI.axios.get<Account>(
    `/accounts/${userId}`,
  );
  return accountsRes.data;
};

export const getProfile = async(userId: string): Promise<Profile> => {
  const profilesRes = await learnlinkAPI.axios.get<Profile>(
    `/profiles/${userId}`,
  );
  return profilesRes.data;
};

export const getUser = async(userId: string): Promise<User> => {
  const usersRes = await learnlinkAPI.axios.get<User>(
    `/users/${userId}`,
  );
  return usersRes.data;
};

export const uploadProfilePhoto = async(userID: string, formData: FormData): Promise<string> => {
  const urlRes = await learnlinkAPI.axios.post<string>(
    `/profiles/uploadProfilePhoto/${userID}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  return urlRes.data;
};
