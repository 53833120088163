import "./publicPath";
import "./sass/vuetify-gucci-layout.scss";
import "@mdi/font/css/materialdesignicons.css";
import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
import VueCompositionAPI from "@vue/composition-api";
import Vue from "vue";
import environment from "./config/environment";
import * as version from "../version.json";

Vue.use(VueCompositionAPI);

Sentry.init({
  Vue,
  environment: environment.sentry.environment,
  dsn: "https://9e7e4c4e023949c988feec5f645c80f8@o104222.ingest.sentry.io/5583849",
  release: `${version.version}`,
  autoSessionTracking: true,
  // TODO: [APP-3038] Sentry integration BrowserTracing not working
  // integrations: [
  //   new Integrations.BrowserTracing(),
  // ],
  logErrors: true,
  tracesSampleRate: 1.0,
  tracingOptions: {
    trackComponents: true,
  },
});
