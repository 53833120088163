

























































import MainMenu from "@/components/app/layout/MainMenu.vue";
import { useRouter } from "@/router";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "AppBar",
  components: { MainMenu },
  setup: () => {
    const { currentRoute } = useRouter();
    const routeName = computed(() => currentRoute.value.name);
    return {
      routeName,
    };
  },
});
