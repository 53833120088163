


















































import { computed, defineComponent, ref } from "@vue/composition-api";
import { getMenuItems } from "@/components/app/layout/MenuItems";
import { userModule } from "@/store/modules/user";
import { useRouter } from "@/router";
import AppBarButtons from "@/components/app/layout/AppBarButtons.vue";

export default defineComponent({
  name: "MainMenu",
  components: {
    AppBarButtons,
  },
  setup() {
    const { navigate, currentRoute } = useRouter();
    const items = computed(() => getMenuItems(userModule.state.user.seller));
    const selectedItem = ref(
      items.value
        .filter(i => !i.divider)
        .findIndex(({ route }) => route.match(currentRoute.value.path)) || 0);

    return {
      currentRoute,
      items,
      selectedItem,
      navigate,
      name: `${userModule.state.profile.firstName} ${userModule.state.profile.lastName}`,
    };
  },
});
