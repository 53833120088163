import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import no from "vuetify/src/locale/no";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { no },
    current: "no",
  },
  theme: {
    themes: {
      light: {
        primary: "#009BFF",
        secondary: "#61c1ff",
        background: "#FFFFFF",
        buttonPrimary: "#a5d4ff",
        buttonSecondary: "#daeaff",
      },
      dark: {
        primary: "#009BFF",
        secondary: "#61c1ff",
        warning: "#c25000",
        background: "#121212",
        buttonPrimary: "#009bff",
        buttonSecondary: "#007fc7",
      },
    },
  },
});
