

















































































































































































import { createSellerAccount } from "@/api/customerService";
import { useNotifier } from "@/providers/notifier";
import { useRouter } from "@/router";
import { RoutesConfig } from "@/router/Routes";
import { auth } from "@/services/firebase";
import { computed, defineComponent, reactive, ref } from "@vue/composition-api";
import TermsOfUse from "./terms-of-use/TermsOfUse.vue";

export default defineComponent({
  name: "SellerRegistration",
  components: {
    TermsOfUse,
  },
  setup() {
    const vm = reactive({
      consent: false,
      isLoading: false,
      seller: {
        address: "",
        birthdate: "",
        city: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        phone: "",
        postalCode: "",
      },
      repeatPassword: "",
      termsVisible: false,
      birthDatePickerVisible: false,
    });
    const validForm = ref(false);
    const notify = useNotifier();
    const { navigate } = useRouter();
    const formRules = {
      required: (v: string) => !!v || "Obligatorisk felt",
      email: {
        validLength: (v: string) => v.length >= 5 || "e-postadressen må være minimum 5 tegn",
        validEmail: (v: string) => /[a-z0-9._%+-]+@[a-z0-9.-]+\.[A-Za-z]{2,4}/.test(v.toLowerCase()) || "e-postadressen er ugyldig ",
      },
      name: {
        validLength: (v: string) => v.length >= 2 || "Fornavn og etternavn må være minst 2 tegn langt",
      },
      password: {
        validLength: (v: string) => v.length >= 6 || "Passordet må være minst 6 tegn langt",
      },
      phone: {
        validChars: (v: string) => /^[0-9]*$/.test(v) || "Ugyldig telefonnummer",
        validLength: (v: string) => v.length >= 8 || "Telefonnummeret skal være minimum 8 siffer",
      },
      postalCode: {
        validChars: (v: string) => /^[0-9]*$/.test(v) || "Ugyldig postnummer",
        validLength: (v: string) => v.length === 4 || "Postnummeret skal være 4 siffer langt",
      },
    };
    const passwordCheck = computed(() => {
      return vm.seller.password === vm.repeatPassword
        ? {
            color: "success",
            icon: "mdi-check-circle",
          }
        : {
            color: "error",
            icon: "mdi-close-circle",
          };
    });

    const register = async() => {
      vm.isLoading = true;
      try {
        await createSellerAccount(vm.seller);
        notify({
          title: "Sendt 🥳",
          message: "Søknaden ble sendt uten problemer. Du blir nå logget inn",
          type: "success",
        });
        await auth.signInWithEmailAndPassword(vm.seller.email, vm.seller.password);
        navigate(RoutesConfig.SELLER_MY_STUDENTS.path);
        // window.location.href = RoutesConfig.SELLER_MY_STUDENTS.path;
      }
      catch (e) {
        if (e.response.status === 422) {
          notify({
            title: "Søknaden er mangelfull 🤯",
            message: "Vennligst fyll ut hele skjemaet. Kontakt support om noe skulle være uklart.",
            type: "error",
          });
        }
        else if (e.response.status === 409) {
          notify({
            title: "E-postadressen er allerede i bruk 🤯",
            message: "Du kan logge inn ved å trykke på knappen oppe til høyre.",
            type: "error",
          });
        }
        else {
          notify({
            title: "Klarte ikke sende søknaden 🤯",
            message: "Kontakt support hvis problemet vedvarer.",
            type: "error",
          });
        }
        // window.Intercom("showNewMessage", "Hei! Jeg opplever problemer med å registrere meg! Kontakt meg på epost eller telefon: ");
      }
      vm.isLoading = false;
    };

    return {
      formRules,
      navigate,
      passwordCheck,
      register,
      validForm,
      vm,
    };
  },

});

