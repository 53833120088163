let refreshing = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let registration: any = null;
// let updateExists = false;

// Listen for our custom event from the SW registration
document.addEventListener("swUpdated", updateAvailable, {
  once: true,
});

// Prevent multiple refreshes
navigator.serviceWorker.addEventListener("controllerchange", () => {
  if (refreshing) {
    return;
  }
  refreshing = true;
  // Here the actual reload of the page occurs
  window.location.reload();
});

// Store the SW registration so we can send it a message
// We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
// To alert the user there is an update they need to refresh for
export function updateAvailable(event: unknown): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  registration = event.detail;
  // updateExists = true;

  refreshApp();
}

// Called when the user accepts the update
export function refreshApp(): void {
  // updateExists = false;
  // Make sure we only send a 'skip waiting' message if the SW is waiting
  if (!registration || !registration.waiting) {
    return;
  }
  // send message to SW to skip the waiting and activate the new SW
  registration.waiting.postMessage({ type: "SKIP_WAITING" });
}
