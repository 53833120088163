














































































































import ContactInformation from "@/interfaces/ContactInformation";
import { defineComponent, PropType, reactive, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "ContactInformationForm",
  props: {
    value: {
      type: Object as PropType<ContactInformation>,
      required: true
    }
  },
  setup(props, { emit }) {
    const _value = reactive(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const vm = reactive({
      repeatPassword: "",
      showPassword: false,
      showRepeatPassword: false,
    });

    const rules = {
      email: (value: string) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (pattern.test(value) || "Ugyldig epostadresse.");
      },
      phone: (value: string) => {
        const pattern = /^[1-9][0-9]{7}$/;
        return (pattern.test(value) || "Ugyldig telefonnummer.");
      },
      match: (v: string) => v === _value.password || "Passordene må være like.",
      min: (v: string) => v.length >= 8 || "Minst 8 tegn."
    };

    // TODO: Form validation should set _value.valid = true;

    return {
      _value,
      rules,
      vm
    };
  }
});
