
























import { defineComponent } from "@vue/composition-api";
import { auth } from "@/services/firebase";

export default defineComponent({
  name: "AppBarButtons",
  setup() {
    return {
      async signOut() {
        auth.signOut();
      },
    };
  },
});
