import { Getters, Mutations, Actions } from "vuex-smart-module";
import { updateAccount, updateProfile, updateUser } from "@/api/userService";
import { dynamicModule } from "../dynamicModuleUtil";
import Account from "@/interfaces/Account";
import Profile from "@/interfaces/Profile";
import User from "@/interfaces/User";

// Must map to the API key
export enum CourseType {
  "Intro" = "IntroCourse",
  "Online" = "OnlineCourse",
  "Tutor" = "TutorCourse",
  "Test" = "TestCourse"
}

interface UserStateInterface {
  userId: string;
  account: Account;
  profile: Profile;
  user: User;
}

const courseApiKey = (type: CourseType) => `completed${type}`;

class UserGetters extends Getters<UserStateInterface> {
  getFullName() {
    return `${this.state.profile.firstName} ${this.state.profile.lastName}`;
  }
}
class UserMutations extends Mutations<UserStateInterface> {
  setAccount(payload: Account) {
    this.state.account = payload;
  }

  setProfile(payload: Profile) {
    this.state.profile = payload;
  }

  setUserObject(payload: User) {
    this.state.user = payload;
  }

  setUserId(userId: string) {
    this.state.userId = userId;
  }
}

class UserActions extends Actions<
  UserStateInterface,
  UserGetters,
  UserMutations,
  UserActions
> {
  async completeCourse(type: CourseType) {
    return this.actions.updateUserProfile({
      [courseApiKey(type)]: true,
    });
  }

  async updateUserAccount(accountUpdate: Partial<Account>) {
    const updatedAccount = await updateAccount({
      uid: this.state.userId,
      ...accountUpdate,
    });
    this.mutations.setAccount(updatedAccount);
  }

  async updateUserProfile(profileUpdate: Partial<Profile>) {
    const updatedProfile = await updateProfile({
      uid: this.state.userId,
      ...profileUpdate,
    });
    this.mutations.setProfile(updatedProfile);
  }

  async updateUserObject(userUpdate: Partial<User>) {
    const updatedUserObject = await updateUser({
      uid: this.state.userId,
      ...userUpdate,
    });
    this.mutations.setUserObject(updatedUserObject);
  }
}

export const userModule = dynamicModule<UserStateInterface, UserGetters, UserMutations, UserActions>(
  "dynamicUser", {
    getters: UserGetters,
    mutations: UserMutations,
    actions: UserActions,
  });
