









export default {
  name: "TermsOfUseCustomer",
};
