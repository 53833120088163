import { Getters, Mutations, Module } from "vuex-smart-module";

class AuthState {
  isAuthorized = false;
  simulate = "";
  uid = "";
}

class AuthGetters extends Getters<AuthState> {}

class AuthMutations extends Mutations<AuthState> {
  authorize(payload: string) {
    this.state.uid = payload;
    this.state.isAuthorized = true;
  }

  deAuthorize() {
    this.state.uid = "";
    this.state.isAuthorized = false;
  }

  simulate(payload: string) {
    this.state.simulate = payload;
  }

  stopSimulation() {
    this.state.simulate = "";
  }
}

const authModule = new Module({
  state: AuthState,
  getters: AuthGetters,
  mutations: AuthMutations,
});

export default authModule;
