import { getAccount, getProfile, getUser } from "@/api/userService";
import { Store } from "vuex";
import { uiModule } from "@/store/modules/ui";
import { userModule } from "./modules/user";

export const loadDynamicModules = async <T>(store: Store<T>, userId: string): Promise<void> => {
  const [account, profile, user] = await Promise.all([
    getAccount(userId),
    getProfile(userId),
    getUser(userId),
  ]);

  userModule.register(store, class {
    userId = userId;
    account = account;
    profile = profile;
    user = user;
  });

  uiModule.register(store, class {
    unreadNewsMessageCount = 0;
  });
};
